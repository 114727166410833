<template>
  <v-dialog v-model="show" :max-width="options.maxWidth" persistent scrollable>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title style="font-weight:700;">
            LINK EXPIRED
            </v-toolbar-title>
          </v-toolbar>

        
        <!-- Content -->
        <v-card-text>
          <div style="text-align:justify;">
          The following documents are protected by federal and state privacy laws. 
          These records are intended only for the use of authorized person(s). 
          For security purposes, this sharing link has expired. To access the shared records, 
          please contact the sender and have them resend the link.

          </div>
        </v-card-text>
        <!-- Actions -->
        <v-card-actions>
          <!-- Buttons -->
          <v-spacer></v-spacer>
          <v-btn depressed dark color="primary" @click="cancelOption()">Dismiss</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
</template>

<style scoped>
.building {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.33;
  text-align: center;
  color: #ff8d16;
}

.content {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  color: #52535f;
}
</style>

<script>
import DialogMixin from '@/core/mixins/DialogMixin'

export default {
  name: 'OneConfirmDialog',
  mixins: [DialogMixin],
  components: {
  },
  data() {
    return {
      className: 'Confirm',
      defaultOptions: {
        okLabel: 'Confirm',
        cancelLabel: 'Cancel',
        showOk: true,
        showCancel: false
      }
    }
  },
  methods: {
    cancelOption() {
      this.$router.push({ name: 'Login' })
    }
  },
  computed: {
    showBuilding() {
      return this.options.unit && this.options.building
    }
  }
}
</script>
