<template>
  <v-layout class="patient-page" justify-center>
    <shared-records-disclaimer-dialog @close="revealFiles()" ref="disclaimerAlert" />
    <expired-link-dialog ref="expiredLink" />
    <v-flex v-if="this.hideFiles" xs12 md10 lg8 xl6>
      <v-layout style="margin-top:50px;" column>
        <v-list>
          <v-skeleton-loader
            :boilerplate="false"
            type="heading"
            :tile="true"
            class="pb-4 px-4 pt-4"
          ></v-skeleton-loader>
          <v-skeleton-loader :boilerplate="false" type="list-item-avatar" :tile="true" class="pb-2"></v-skeleton-loader>
          <v-skeleton-loader
            :boilerplate="false"
            type="list-item-avatar-two-line"
            :tile="true"
            class="pb-2"
          ></v-skeleton-loader>
          <v-skeleton-loader :boilerplate="false" type="list-item-avatar" :tile="true" class="pb-2"></v-skeleton-loader>
          <v-skeleton-loader
            :boilerplate="false"
            type="list-item-avatar-two-line"
            :tile="true"
            class="pb-2"
          ></v-skeleton-loader>
          <v-skeleton-loader :boilerplate="false" type="list-item-avatar" :tile="true" class="pb-2"></v-skeleton-loader>
          <v-skeleton-loader
            :boilerplate="false"
            type="list-item-avatar-two-line"
            :tile="true"
            class="pb-2"
          ></v-skeleton-loader>
          <v-skeleton-loader :boilerplate="false" type="list-item-avatar" :tile="true" class="pb-2"></v-skeleton-loader>
          <v-skeleton-loader
            :boilerplate="false"
            type="list-item-avatar-two-line"
            :tile="true"
            class="pb-2"
          ></v-skeleton-loader>
        </v-list>
      </v-layout>
    </v-flex>
    <v-flex v-else xs12 md10 lg8 xl6>
      <v-card outlined tile style="margin-top:30px" flat>
        <v-layout row justify-center class="pt-2">
          <img
            v-if="appTheme"
            class="mx-5 px-5 mt-5"
            style="width: 300px; max-height: 300px; object-fit: contain;"
            :src="appTheme.login"
          />
        </v-layout>
        <v-layout v-if="appTheme" class="pt-4 pb-4" justify-center row>
          <span style="max-width: 300px; text-size:13px; text-align: center; color: #909090"
            >Shared Patient Records
          </span>
        </v-layout>
        <v-layout justify-center>
          <v-flex xs12>
            <v-layout class="px-3 mx-1" row wrap>
              <v-flex xs12 md6 lg4>
                <v-text-field
                  hide-details
                  readonly
                  outlined
                  class="ma-1"
                  label="First Name"
                  v-model="sharedPatient.firstName"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12 md6 lg4>
                <v-text-field
                  hide-details
                  readonly
                  outlined
                  class="ma-1"
                  label="Last Name"
                  v-model="sharedPatient.lastName"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs6 md6 lg2>
                <v-text-field hide-details readonly outlined class="ma-1" label="Sex" v-model="sharedPatient.gender">
                </v-text-field>
              </v-flex>
              <v-flex xs6 md6 lg2>
                <v-text-field
                  hide-details
                  readonly
                  outlined
                  class="ma-1"
                  label="Date of Birth"
                  v-model="sharedPatient.birthdate"
                >
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-card-text class="px-5">
              Access to these records will expire in <span style="color: red">{{ countdownString }}</span
              >. To save these files to the cloud and retain permanent access, select 'Get Permanent Access' below.
            </v-card-text>
          </v-flex>
        </v-layout>
      </v-card>

      <v-card outlined class="mt-5 mb-5" flat tile>
        <v-data-table
          style="max-width: 100%"
          :items-per-page="500"
          :headers="headers"
          :items="sharedFiles"
          hide-default-footer
          class="elevation-0"
          :hide-default-header="$vuetify.breakpoint.smAndDown"
        >
          <!-- <template v-slot:body="{ items }"> -->
          <template v-slot:body="{}">
            <tbody style="cursor:pointer;">
              <tr
                v-for="(item, index) in sharedFiles"
                :key="index"
                :class="alternatingColors(index)"
                @click="fileSelected(item)"
              >
                <td style="width:30px;" valign="top" class="pt-2">
                  <img style="width: 30px; height:30px;" draggable="false" :src="item.fileIconURL" />
                </td>
                <td valign="top" class="pt-3 pb-3" style="font-weight:500">
                  {{ item.recordTypeDisplayName }}
                </td>
                <td v-if="!isMobile" valign="top" class="pt-3 pb-3" style="opacity:0.7">
                  {{ item.description }}
                </td>
                <td v-if="!isMobile" valign="top" class="pt-3 pb-3">
                  {{ item.providerDisplayName }}
                </td>
                <td valign="top" class="pt-3 pb-3">
                  {{ item.displayDate }}
                </td>
                <td v-if="!isMobile" valign="top" style="width:110px;" class="text-center pt-1">
                  <v-btn style="opacity:0.5" @click.native.stop.prevent="fileSelectedForDownload(item)" icon
                    ><v-icon>mdi-cloud-download</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
    <v-footer v-if="!hideFiles" :style="footerStyle" fixed :padless="false">
      <v-layout column class="pa-2">
        <v-layout row justify-end>
          <v-card v-if="!isMobile" outlined class="elevation-5">
            <v-card-text>
              <v-layout column>
                <div style="text-align:center">
                  <span style="font-weight:700; ">Access Expires: </span>
                  <span style="font-weight:700; color: red">{{ countdownString }}</span>
                </div>
                <v-btn @click="switchToSignIn" class="mt-1 mb-2" color="primary" depressed>Get Permanent Access</v-btn>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-layout>
        <div v-if="isMobile" style="text-align:right">
          <span style="font-weight:700; ">Access Expires: </span>
          <span style="font-weight:700; color: red">{{ countdownString }}</span>
        </div>
        <v-btn v-if="isMobile" @click="switchToSignIn" class="mt-1 mb-2" color="primary" depressed
          >Get Permanent Access</v-btn
        >
      </v-layout>
    </v-footer>
  </v-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import SharedRecordsDisclaimerDialog from '@/components/dialogs/SharedRecordsDisclaimerDialog'
import ExpiredLinkDialog from '@/components/dialogs/ExpiredLinkDialog'

export default {
  mixins: [HelperMixin],
  data() {
    return {
      countdownString: null,
      countdownValue: null,
      privacyAgreement: null,
      linkExpired: false,
      hideFiles: true,
    }
  },
  components: {
    SharedRecordsDisclaimerDialog,
    ExpiredLinkDialog,
  },
  computed: {
    ...mapState('patient', [
      'sharedPatient',
      'sharedFiles',
      'sharedFilesExpiration',
      'temporaryAuthToken',
      'shareExpired',
    ]),
    ...mapState('provider', ['localProviderList']),
    ...mapState('auth', ['appTheme']),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
    footerStyle() {
      if (this.isMobile) {
        return 'border-top:1px solid #f5f5f5; background-color: white'
      }
      return 'background-color: transparent;'
    },
    headers() {
      if (this.isMobile) {
        return [
          {
            text: '',
            align: 'left',
            value: null,
          },
          {
            text: 'Document',
            align: 'left',
            value: 'type',
            sortable: false,
          },
        ]
      }
      return [
        {
          text: '',
          align: 'left',
          value: null,
        },
        {
          text: 'Type',
          align: 'left',
          value: 'type',
          sortable: false,
        },
        {
          text: 'Description',
          align: 'left',
          value: 'note',
          sortable: false,
        },
        {
          text: 'Provider',
          align: 'left',
          value: 'providerDisplayName',
          sortable: false,
        },
        {
          text: 'Date',
          align: 'left',
          value: 'displayDate',
          sortable: false,
        },
        {
          text: 'Download',
          align: 'center',
          value: '',
          sortable: false,
        },
      ]
    },
  },
  watch: {
    shareExpired(val) {
      if (val) {
        console.log('share expired')
        this.hideFiles = true
        this.$refs.expiredLink.open({})
      }
    },
    localProviderList(val) {
      this.sharedFiles.forEach(file => {
        if (file.renderingProvider) {
          if (val[file.renderingProvider]) {
            file.providerDisplayName = val[file.renderingProvider].selectionDisplayName
          }
        }
      })
    },
    sharedFiles(val) {
      let providerIDs = []
      val.forEach(file => {
        if (file.renderingProvider) {
          if (!providerIDs.includes(file.renderingProvider)) {
            providerIDs.push(file.renderingProvider)
          }
        }
      })
      this.setTheLocalProviderList({ ids: providerIDs })
    },
  },
  methods: {
    ...mapActions('provider', ['setTheLocalProviderList']),
    ...mapActions('file', ['openSelectedFileInNewWindowMobile', 'openSelectedFileInNewWindowDesktop', 'downloadFile']),
    ...mapActions('auth', ['userActivity']),
    fileSelectedForDownload(file) {
      file.temporaryAuthToken = this.temporaryAuthToken
      this.downloadFile(file)
    },
    switchToSignIn() {
      let share = { share: this.$route.params.id }
      this.$router.push({ name: 'Login', query: share })
    },
    fileSelected(file) {
      file.temporaryAuthToken = this.temporaryAuthToken
      if (this.isMobile) {
        this.openSelectedFileInNewWindowMobile(file)
      } else {
        this.openSelectedFileInNewWindowDesktop(file)
      }
    },
    revealFiles() {
      this.userActivity({
        patient: this.sharedPatient._id,
        type: 'view_shared_unauthenticated',
        payload: { share: this.$route.params.id },
      })
      this.hideFiles = false
    },
  },
  mounted() {
    // fetch the files.
    // check if expired
    // if not expired, display the privacy dialog
    // if expired, display the expired dialog
    this.hideFiles = true
    this.linkExpired = false
  },
}
</script>

<style></style>
